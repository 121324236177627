import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : '1200px')};
  margin: 0 auto;
  padding: 0 3rem;
`

const Container = ({ children, className, ...otherprops }) => {
  return (
    <Wrapper className={className} {...otherprops}>
      {children}
    </Wrapper>
  )
}

export default Container

Container.defaultProps = {
  className: null
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}
