import React from 'react'
import { ButtonHamburger } from './styles'

export const ButtonMenu = (props) => {
  return (
    <ButtonHamburger onClick={props.handleClick} className={props.isActive ? 'active' : ''}>
      <span></span>
    </ButtonHamburger>
  )
}

export default ButtonMenu
