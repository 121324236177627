import styled, { css } from 'styled-components'
import LocalizedLink from 'components/LocalizedLink'

export const NavigationWrapper = styled.nav`
  display: flex;
  background: white;
  margin-top: ${({ topMargin }) => (topMargin ? '4rem' : '0')};
  ${({ noLeftMargin }) =>
    noLeftMargin
      ? css`
          a:first-child {
            margin-left: 0;
          }
        `
      : null}
  @media screen and (max-width: 1024px) {
    display: none;

    &.is-active {
      display: flex;
      align-items: center;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      transform: translateY(64px);
      padding: 1rem 0;
      left: 0;
      right: 0;
      position: absolute;
      justify-content: center;

      a:first-child {
        margin-left: 15px;
      }
    }
  }

  &.landing-mode {
    display: flex;
  }
`

export const NavigationLink = styled(LocalizedLink)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.color.black};
  margin: 0 15px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    transition: width 0.2s ease-in-out;
    width: 0;
  }
  &:hover {
    &::after {
      height: 3px;
      background-color: ${({ theme }) => theme.color.vividBlue};
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
  &.active {
    color: ${({ theme }) => theme.color.vividBlue};
    transition: color 0.2s ease-in-out;
  }
`
