import React from 'react'
import useTranslations from '../useTranslations'
import { useMenu } from 'hooks/menu'

import { HeaderWrapper, HeaderBox } from './styles'

import Navigation from 'elements/Navigation'
import ButtonMenu from 'elements/ButtonMenu'
import Logo from 'elements/Logo'
import Container from 'elements/Container'
import LocalizedLink from '../LocalizedLink'

const Header = () => {
  const { home } = useTranslations()
  const { openedMenu, toggleMenu } = useMenu()
  return (
    <HeaderWrapper>
      <Container maxWidth="100%">
        <HeaderBox>
          <ButtonMenu handleClick={toggleMenu} isActive={openedMenu} />
          <Navigation noLeftMargin="true" className={openedMenu ? 'is-active' : ''} />
          <LocalizedLink to="/" title={home} aria-label={home}>
            <Logo />
          </LocalizedLink>
        </HeaderBox>
      </Container>
    </HeaderWrapper>
  )
}

export default Header
