import React from 'react'


import { GlobalStyles, theme } from '../../theme/globalStyles'
import { ThemeProvider } from 'styled-components'

import Header from '../../components/Header'

import { useLocale } from '../../hooks/locale'
import { useEffect } from 'react'
// import PageTransition from 'gatsby-plugin-page-transitions';
import Transition from '../../components/Transition'

const BaseLayout = ({ children, pageContext: { locale }, location }) => {
  // Using the useLocale() hook to define the correct locale
  // that will be available in all components of the tree thought its context
  const { changeLocale } = useLocale()
  // changeLocale(locale)
  useEffect(() => {
    changeLocale(locale)
  })

  return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Transition location={location}>
          {location.pathname === '/' || location.pathname === '/hr/' || location.pathname === '/hr' ? (
           children
          ) : (
            <>
            <Header />
       
              <main>{children}</main>
            </>
          )}
          </Transition>
      </ThemeProvider>
    
  )
}

export default BaseLayout
