import React from 'react'
import AppProvider from './src/hooks'
import BaseLayout from './src/elements/Layouts'

const wrapPageElement = ({ element, props }) => (
  <AppProvider>
    <BaseLayout {...props}>{element}</BaseLayout>
  </AppProvider>
)

export default wrapPageElement
