import styled from 'styled-components'

export const HeaderWrapper = styled.header`
  position: fixed;
  height: 100px;
  width: 100%;
  z-index: 1;
  background: white;

  img {
    max-width: 105px;
  }
`
export const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
`
