import React from 'react'
import useMenu from '../../components/useMenu'
// import useTranslations from '../../components/useTranslations'
import { useMenu as useMenuHook } from 'hooks/menu'

import { NavigationWrapper, NavigationLink } from './styles'

const Navigation = ({ topMargin, noLeftMargin, className }) => {
  const menuItems = useMenu()
  const { toggleMenu } = useMenuHook()
  return (
    <NavigationWrapper topMargin={topMargin} noLeftMargin={noLeftMargin} className={className}>
      {menuItems.map((menu, index) => {
        return (
          <NavigationLink
            to={menu.link}
            onClick={toggleMenu}
            aria-label={menu.name}
            key={`${menu.link}${index}`}
            activeClassName="active"
          >
            {menu.name}
          </NavigationLink>
        )
      })}
    </NavigationWrapper>
  )
}

export default Navigation
