// Only one item MUST have the "default: true" key

module.exports = {
  en: {
    default: true,
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Mato Njavro`,
    defaultDescription: `test EN`
  }
  // hr: {
  //   path: `hr`,
  //   locale: `hr-HR`,
  //   dateFormat: `DD/MM/YYYY`,
  //   siteLanguage: `hr`,
  //   ogLanguage: `hr_HR`,
  //   defaultTitle: `Mato Njavro`,
  //   defaultDescription: `test HR`
  // }
}
